import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About page" />
        <div className="col-lg-8 p1050">
          <article className="sppa">
            <header className="arhd">
              <h1 className="arhl">Tentang Kami</h1>
            </header>

            <div className="tdpc">
              <p>Didirikan pada tahun 2019, Membisnis.com bertujuan untuk menyajikan liputan berita keuangan yang akurat dan aktual kepada para pembacanya. Situs web kami berfokus pada segmen-segmen utama di pasar keuangan - saham, mata uang dan komoditas, serta penjelasan yang interaktif dan mendalam tentang peristiwa-peristiwa dan indikator-indikator ekonomi utama. Fokus kami bahkan lebih jauh lagi dengan menyajikan strategi trading yang berdasar pada analisis fundamental dan teknis, kepada trader pemula, maupun trader yang lebih berpengalaman. </p>
              <p>Tujuan terpenting dari pekerjaan kami adalah memastikan terjadinya hubungan antara para pembaca dengan broker forex dan CFD yang paling dapat diandalkan.</p>  
              <p>Dengan mengumpulkan informasi penting dan data kunci yang kami sajikan dalam bentuk ulasan broker, kami memfasilitasi keputusan yang diinformasikan oleh para calon investor.</p> 
            </div>
          </article>

        </div> 
        
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
